import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
import { IonicVue } from '@ionic/vue';
import '@ionic/vue/css/core.css';
import '@ionic/vue/css/normalize.css';
import 'swiper/css';
import '@ionic/vue/css/ionic-swiper.css';
import 'animate.css';
import './registerServiceWorker';
import {loadTranslations} from "@/helpers/translator.js";
import '@/theme/ready2eat/scss/app.scss';
import '@/scss/index.scss';
import store from "@/store/store.js";
import PluginService from "@/core/pluginService.js";
import {appConfigurationService} from "./modules/app-configuration/services/AppConfigurationService.js";

async function initApp() {
  const app = createApp(App)
    .use(IonicVue)
    .use(store)
    .use(router)

  // Initialize all plugins
  await PluginService.initializeAll(app, router);

  // Load translations and mount the app
  await loadTranslations().then(() => {
    app.mount('#app');
  });

  // get application configuration
  await appConfigurationService.loadConfiguration();

  // listen to event to initialize plugins
  window.addEventListener('app:tracking', async () => {
    await PluginService.initializeAll(app);
  });
}



initApp();
