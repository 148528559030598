const icons = {
  'chevron': import('@/svg/ui/chevron.svg?raw'),
  'phone': import('@/svg/ui/phone.svg?raw'),
  'check': import('@/svg/ui/check.svg?raw'),
  'plus': import('@/svg/ui/plus.svg?raw'),
  'logo': import('@/svg/logo/logo.svg?raw'),
  'logo-big-icon': import('@/svg/logo/logo-big-icon.svg?raw'),
  'logo-text': import('@/svg/logo/logo-text.svg?raw'),
  'exit': import('@/svg/ui/exit.svg?raw'),
  'edit': import('@/svg/ui/edit.svg?raw'),
  'user': import('@/svg/ui/user.svg?raw'),
  'bag': import('@/svg/ui/bag.svg?raw'),
  'history': import('@/svg/ui/history.svg?raw'),
  'addresses': import('@/svg/ui/addresses.svg?raw'),
  'favorites': import('@/svg/ui/favorites.svg?raw'),
  'info': import('@/svg/ui/info.svg?raw'),
  'info-full': import('@/svg/ui/info-full.svg?raw'),
  'search': import('@/svg/ui/search.svg?raw'),
  'location': import('@/svg/ui/location.svg?raw'),
  'clock': import('@/svg/ui/clock.svg?raw'),
  'star': import('@/svg/ui/star.svg?raw'),
  'heart': import('@/svg/ui/heart.svg?raw'),
  'filter': import('@/svg/ui/filter.svg?raw'),
  'minus': import('@/svg/ui/minus.svg?raw'),
  'success': import('@/svg/status/success.svg?raw'),
  'error': import('@/svg/status/error.svg?raw'),
  'arrow': import('@/svg/ui/arrow.svg?raw'),
  'delete': import('@/svg/ui/delete.svg?raw'),
  'user-location': import('@/svg/ui/user-location.svg?raw'),
  'discount': import('@/svg/ui/discount.svg?raw'),
  'card': import('@/svg/ui/card.svg?raw'),
  'r2h': import('@/svg/ui/r2h.svg?raw'),
  'cash': import('@/svg/ui/cash.svg?raw'),
  'kbc': import('@/svg/providers/kbc.svg?raw'),
  'belfius': import('@/svg/providers/belfius.svg?raw'),
  'bancontact': import('@/svg/providers/bancontact.svg?raw'),
  'mastercard': import('@/svg/providers/mastercard.svg?raw'),
  'visa': import('@/svg/providers/visa.svg?raw'),
  'address-establishment': import('@/svg/ui/address-establishment.svg?raw'),
  'empty': import('@/svg/img/empty.svg?raw'),
  'status.queuing': import('@/svg/status/queuing.svg?raw'),
  'status.received': import('@/svg/status/received.svg?raw'),
  'status.confirmed': import('@/svg/status/confirmed.svg?raw'),
  'status.paid': import('@/svg/status/paid.svg?raw'),
  'status.in_preparation': import('@/svg/status/in_preparation.svg?raw'),
  'status.ready_to_go': import('@/svg/status/ready_to_go.svg?raw'),
  'status.in_delivery': import('@/svg/status/in_delivery.svg?raw'),
  'status.complete': import('@/svg/status/complete.svg?raw'),
  'loader': import('@/svg/loader/loader.svg?raw'),
  'invoice': import('@/svg/ui/invoice.svg?raw'),
  'order': import('@/svg/ui/order.svg?raw'),
  'allergy.info': import('@/svg/ui/info.svg?raw'),
  'allergy.lactose': import('@/svg/allergies/lactose.svg?raw'),
  'allergy.gluten': import('@/svg/allergies/gluten.svg?raw'),
  'allergy.nuts': import('@/svg/allergies/nuts.svg?raw'),
  'allergy.eggs': import('@/svg/allergies/eggs.svg?raw'),
  'allergy.fish': import('@/svg/allergies/fish.svg?raw'),
  'allergy.crustaceans': import('@/svg/allergies/crustaceans.svg?raw'),
  'allergy.soy': import('@/svg/allergies/soy.svg?raw'),
  'allergy.pinda': import('@/svg/allergies/pinda.svg?raw'),
  'allergy.sesame': import('@/svg/allergies/sesame.svg?raw'),
  'allergy.sulfur_dioxide_sulphites': import('@/svg/allergies/sulfur_dioxide_&_sulphites.svg?raw'),
  'allergy.lupine': import('@/svg/allergies/lupine.svg?raw'),
  'allergy.mollusks': import('@/svg/allergies/mollusks.svg?raw'),
  'allergy.mustard': import('@/svg/allergies/mustard.svg?raw'),
  'allergy.celery': import('@/svg/allergies/celery.svg?raw'),
  'alcohol': import('@/svg/ui/alcohol.svg?raw'),
  'sort': import('@/svg/ui/sort.svg?raw'),
  'radio': import('@/svg/ui/radio.svg?raw'),
  'radio-active': import('@/svg/ui/radio-active.svg?raw'),
  'update': import('@/svg/ui/update.svg?raw'),
  'error.not-found': import('@/svg/errors/not-found.svg?raw'),
  'error.maintenance': import('@/svg/errors/maintenance.svg?raw'),
  'server': import('@/svg/errors/server.svg?raw'),
  'error.internet': import('@/svg/errors/internet.svg?raw'),
  'contact-user': import('@/svg/ui/contact-user.svg?raw'),
  'alert': import('@/svg/errors/alert.svg?raw'),
  'catalogue': import('@/svg/ui/catalogue.svg?raw'),
  'cart-button-background': import('@/svg/ui/cart-button-background.svg?raw'),
  'no-results': import('@/svg/errors/no-results.svg?raw'),
  'settings': import('@/svg/ui/settings.svg?raw'),
  'facebook': import('@/svg/providers/facebook.svg?raw'),
  'google': import('@/svg/providers/google.svg?raw'),
  'add': import('@/svg/ui/plus.svg?raw'),
  'min': import('@/svg/ui/minus.svg?raw'),
  'map-light': import('@/svg/map/map-light.svg?raw'),
  'map-dark': import('@/svg/map/map-dark.svg?raw'),
  'eye': import('@/svg/ui/eye.svg?raw'),
  'eye-closed': import('@/svg/ui/eye-closed.svg?raw'),
  'mollie': import('@/svg/providers/mollie.svg?raw'),
  'version': import('@/svg/ui/version.svg?raw'),
  'beverage': import('@/svg/ui/beverage.svg?raw'),
  'menu': import('@/svg/ui/menu.svg?raw'),
  'apple': import('@/svg/providers/apple.svg?raw'),
  'payment-failed': import('@/svg/errors/payment-failed.svg?raw'),
  'logout': import('@/svg/ui/logout.svg?raw'),
}


export default icons;
