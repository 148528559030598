import PlatformService from "@/core/PlatformService.js";
import {EdgeToEdge} from "@capawesome/capacitor-android-edge-to-edge-support";
import {StatusBar, Style} from "@capacitor/status-bar";

const changeAppBackground = async (routeFrom, route) => {
  const root = document.documentElement;
  const body = document.body;
  const isDarkMode = root.getAttribute('data-theme') === 'dark';

  const defaultColor = isDarkMode ? '#1C1F2B' : '#FCFCFC';

  const newColor = route.meta.backgroundColor
    ? (isDarkMode ? route.meta.backgroundColor.dark : route.meta.backgroundColor.light)
    : defaultColor;

  const oldColor = routeFrom?.meta?.backgroundColor
    ? (isDarkMode ? routeFrom.meta.backgroundColor.dark : routeFrom.meta.backgroundColor.light)
    : defaultColor;

  // Set background colors always for web
  root.style.setProperty('--app-background', newColor);
  body.style.setProperty('background-color', newColor);

  if(newColor !== oldColor && isDarkMode && PlatformService.isNative() && PlatformService.isAndroid()) {
    const style = Style.Dark;

    await EdgeToEdge.setBackgroundColor({ color: newColor });
    await StatusBar.setStyle({ style: style });
    await StatusBar.setBackgroundColor({ color: newColor });
  }

  // Only trigger native changes if background color has changed
  if (newColor !== oldColor && PlatformService.isNative() && PlatformService.isAndroid()) {
    //const style = isDarkMode ? Style.Dark : Style.Light;
    const isDeviceDarkMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;

    let style = isDeviceDarkMode.Dark ? Style.Light : Style.Dark;

    console.log('newColor', newColor);

    if(newColor === '#FCFCFC') {
      style = Style.Light;
    }

    await EdgeToEdge.setBackgroundColor({ color: newColor });
    await StatusBar.setStyle({ style: style });
    await StatusBar.setBackgroundColor({ color: newColor });
  }
};

const initBackgroundChange = async (darkTheme) => {
  const root = document.documentElement;
  const body = document.body;

  if(darkTheme && PlatformService.isNative()) {
    const style = Style.Dark;

    root.style.setProperty('--app-background', '#1C1F2B');
    body.style.setProperty('background-color', '#1C1F2B');

    if(PlatformService.isAndroid()) {
      await EdgeToEdge.setBackgroundColor({ color: '#1C1F2B' });
      await StatusBar.setStyle({ style: style });
      await StatusBar.setBackgroundColor({ color: '#1C1F2B' });
    }
  } else if (PlatformService.isNative()) {
    const isDeviceDarkMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;

    let style = isDeviceDarkMode.Dark ? Style.Light : Style.Dark;

    root.style.setProperty('--app-background', '#FC2352');
    body.style.setProperty('background-color', '#FC2352');

    if(PlatformService.isAndroid()) {
      await EdgeToEdge.setBackgroundColor({ color: '#FC2352' });
      await StatusBar.setStyle({ style: style });
      await StatusBar.setBackgroundColor({ color: '#FC2352' });
    }

  }
}

export {changeAppBackground, initBackgroundChange};
