<template>
  <AppModal :scrollable="false">
    <div class="navigation" :class="{'native-padding': native}">
      <navigation-header
        @close="close"
        @navigate="goTo"
      />
      <div class="nav-main">
        <container>
          <follow-order-button v-if="currentOrders.length > 0" />

          <navigation-links
            class="mt-l"
            :current-orders="currentOrders"
            @navigate="goTo"
          />
          <div v-if="authenticated" class="d-flex">
            <navigation-logout-button
              @logout="doLogout"
            />
          </div>
        </container>
      </div>
    </div>
  </AppModal>
</template>

<script setup>
  import {onBeforeMount, ref} from 'vue';
  import {useRouter} from 'vue-router';
  import {modalController} from '@ionic/vue';
  import AppModal from '@/modals/AppModal.vue';
  import Container from '@/layout/layout/Container.vue';
  import NavigationLogoutButton from "@/modules/navigation/parts/NavigationLogoutButton.vue";
  import NavigationLinks from "@/modules/navigation/parts/NavigationLinks.vue";
  import NavigationHeader from "@/modules/navigation/parts/NavigationHeader.vue";
  import {$showToast, __t} from "@/globals.js";
  import {userService} from "@/services/app/userService.js";
  import PlatformService from "@/core/PlatformService.js";
  import {userAuthenticationService} from "@/flows/user/authentication/services/UserAuthenticationService.js";
  import FollowOrderButton from "@/flows/order/follow/components/parts/FollowOrderButton.vue";

  const router = useRouter();

  const currentOrders = ref([]);
  const authenticated = ref(false);
  const native = PlatformService.isNative();

  function close() {
    modalController.dismiss();
  }

  async function removeOrder(orderNr) {
    await userService.removeOrder(orderNr);
    currentOrders.value = await userService.getCurrentOrders();
  }

  async function doLogout() {
    await userAuthenticationService.logout();

    await router.push({name: 'login'});

    $showToast(__t('success.logout'), 'success');

    close();
  }

  function goTo(route, params) {
    close();
    router.push({name: route, params});
  }

  onBeforeMount(async () => {
    authenticated.value = await userService.isAuthenticated();
    currentOrders.value = await userService.getCurrentOrders();
  });
</script>

<style lang="scss">
@import "@/scss/smooth";

//.ion-modal-navigation {
//  overflow: hidden !important;
//
//  @media(min-height: 400px) {
//    .ion-page {
//      overflow: hidden !important;
//    }
//
//    ion-content {
//      --overflow: hidden;
//      overflow: hidden !important;
//    }
//  }
//}

.navigation {
  display: flex;
  border-radius: 0;
  width: 100%;
  background-color: var(--r2e-background);
  padding-top: var(--padding-m);
  flex-direction: column;

  &.native-padding {
    padding-top: 6rem;
  }

  a:hover {
    cursor: pointer;
  }

  .nav-main {
    padding: var(--padding-m) var(--padding-xs);
    padding-top: 0;

    @media(min-height: 700px) {
      padding: var(--padding-l) var(--padding-xs);
      padding-top: 0;
    }

    .d-flex {
      display: flex;
    }
  }
}

[data-theme='dark'] {
  .navigation {
    background-color: var(--r2e-secondary-600);
  }
}
</style>
