import {cartStorage} from "@/flows/order/cart/stores/cartStorage.js";
import store from "@/store/store.js";
import CartLine from "@/resources/CartLine.js";
import CartSummary from "@/resources/CartSummary.js";
import CartEstablishment from "@/resources/CartEstablishment.js";
import {discountService} from "@/modules/discount/services/DiscountService.js";
import {preferenceService} from "@/flows/app/preferences/services/preferenceService.js";

class CartService {
  constructor() {
    this.cartStorage = cartStorage;
  }

  async setDeliveryMode(value) {
    try {
      await this.cartStorage.set('delivery_mode', value);

      if(value !== 'delivery'){
        await preferenceService.setPaymentMethod('online');
      }

      await this.calculateCartOnDeliveryModeChange(value);
    } catch (error) {
      throw new Error('Failed to set delivery mode:', error);
    } finally {
      document.dispatchEvent(new Event('delivery_mode:updated'));
    }
  }

  async getDeliveryMode() {
    try {
      return await this.cartStorage.get('delivery_mode') || 'delivery';
    } catch (error) {
      throw new Error('Failed to get delivery mode:', error);
    }
  }

  async getEstablishment() {
    try {
      return await this.cartStorage.get('establishment') || null;
    } catch (error) {
      throw Error('Failed to get establishment:', error);
    }
  }


  async addCartLine(cartLine, establishment) {
    try {
      await this.checkForCartLineEstablishment(establishment);

      const cartLineObj = new CartLine(cartLine);
      let cartLines = await this.cartStorage.getCartLines();

      // Extract sorting value, placing undefined, null, or 0 at the end
      const cartLineSorting = cartLineObj.article.receipt_sorting ?? Number.MAX_SAFE_INTEGER;

      let inserted = false;
      for (let i = 0; i < cartLines.length; i++) {
        const existingSorting = cartLines[i].article.receipt_sorting ?? Number.MAX_SAFE_INTEGER;

        if (cartLineSorting < existingSorting) {
          cartLines.splice(i, 0, cartLineObj);
          inserted = true;
          break;
        }
      }

      if (!inserted) {
        cartLines.push(cartLineObj);
      }

      await this.cartStorage.setCartLines(cartLines);
      await store.dispatch('updateCartStatus');
      await this.calculateCartSummary();
    } catch (error) {
      throw Error('Failed to add cart line:', error);
    }
  }

  async getCartLines() {
    try {
      let cartLines = await this.cartStorage.getCartLines();

      // Sort cart lines based on article.receipt_sorting
      return cartLines.sort((a, b) => {
        const aSorting = a.article.receipt_sorting ?? Number.MAX_SAFE_INTEGER;
        const bSorting = b.article.receipt_sorting ?? Number.MAX_SAFE_INTEGER;
        return aSorting - bSorting;
      });
    } catch (error) {
      console.error('Failed to get cart lines:', error);
      return [];
    }
  }

  async addCartLineQuantity(uuid) {
    try {
      let cartLines = await this.cartStorage.getCartLines();

      const cartLineIndex = cartLines.findIndex(line => line.uuid === uuid);

      if (cartLineIndex !== -1) {
        const cartLine = new CartLine(cartLines[cartLineIndex]);
        cartLine.addQuantity();

        cartLines[cartLineIndex] = cartLine;

        await this.cartStorage.setCartLines(cartLines);

        await this.calculateCartSummary();

        return cartLine;
      }
    } catch (error) {
      console.error('Failed to add cart line quantity:', error);
    }
  }

  async minCartLineQuantity(uuid) {
    try {
      let cartLines = await this.cartStorage.getCartLines();

      const cartLineIndex = cartLines.findIndex(line => line.uuid === uuid);

      if (cartLineIndex !== -1) {
        const cartLine = new CartLine(cartLines[cartLineIndex]);
        cartLine.minQuantity();

        cartLines[cartLineIndex] = cartLine;

        await this.cartStorage.setCartLines(cartLines);

        await this.calculateCartSummary();

        return cartLine;
      }
    } catch (error) {
      throw Error('Failed to min cart line quantity:', error);
    }
  }

  async removeCartLine(uuid) {
    try {
      let cartLines = await this.cartStorage.getCartLines();

      const cartLineIndex = cartLines.findIndex(line => line.uuid === uuid);

      if (cartLineIndex !== -1) {
        cartLines.splice(cartLineIndex, 1);

        await this.cartStorage.setCartLines(cartLines);

        await this.calculateCartSummary();

        await store.dispatch('updateCartStatus');
      }
    } catch (error) {
      throw Error('Failed to remove cart line:', error);
    }
  }

  async addCartLineComment(uuid, comment) {
    try {
      let cartLines = await this.cartStorage.getCartLines();

      const cartLineIndex = cartLines.findIndex(line => line.uuid === uuid);

      if (cartLineIndex !== -1) {
        const cartLine = new CartLine(cartLines[cartLineIndex]);
        cartLine.addComment(comment);

        cartLines[cartLineIndex] = cartLine;

        await this.cartStorage.setCartLines(cartLines);

        await store.dispatch('updateCartStatus');
      }
    } catch (error) {
      throw Error('Failed to add cart line comment:', error);
    }
  }

  async calculateCartSummary() {
    try {

      const cartSummary = await this.cartStorage.getCartSummary();
      const cartLines = await this.cartStorage.getCartLines();
      const deliveryMode = await this.getDeliveryMode();
      const deliveryCost = await this.getDeliveryCost();
      const paymentMethod = await preferenceService.getPaymentMethod();

      const cartSummaryObj = new CartSummary(cartSummary);

      cartSummary.delivery_cost = deliveryCost;

      cartSummaryObj.calculate(cartLines, deliveryMode, paymentMethod);

      await this.cartStorage.setCartSummary(cartSummaryObj);

      await store.dispatch('updateCartSummaryStatus');

    }catch(error) {
      throw Error('Failed to calculate cart summary:', error);
    }
  }

  async setMinOrderAmount(amount) {
    try {
      const cartSummary = await this.cartStorage.getCartSummary();

      const cartSummaryObj = new CartSummary(cartSummary);

      cartSummaryObj.min_order_amount = amount;

      await this.cartStorage.setCartSummary(cartSummaryObj);

      await this.calculateCartSummary();

      await store.dispatch('updateCartSummaryStatus');
    } catch (error) {
      console.error('Failed to set min order amount:', error);
    }
  }

  async setDeliveryCost(amount) {
    try {
      const cartSummary = await this.cartStorage.getCartSummary();

      const cartSummaryObj = new CartSummary(cartSummary);

      cartSummaryObj.delivery_cost = amount;

      await this.cartStorage.setCartSummary(cartSummaryObj);

      await this.calculateCartSummary();

      await store.dispatch('updateCartSummaryStatus');
    } catch (error) {
      console.error('Failed to set delivery cost:', error);
      throw Error('Failed to set delivery cost:', error);
    }
  }

  async getCartSummary() {
    try {
      const summary = await this.cartStorage.getCartSummary();

      return new CartSummary(summary);
    } catch (error) {
      console.error('Failed to get cart summary:', error);
      return new CartSummary();
    }
  }

  async setDiscountCode(discountCode) {
    try {
      const cartSummary = new CartSummary(await this.cartStorage.getCartSummary());

      cartSummary.setDiscountCode(discountCode);

      await this.cartStorage.setCartSummary(cartSummary);

      await this.calculateCartSummary();

      await discountService.dismissDiscountCode(discountCode);

      await store.dispatch('updateOrderPreferences');
    } catch (error) {
      throw Error('Failed to set discount code:', error);
    }
  }

  async removeDiscountCode() {
    try {
      const cartSummary = await this.cartStorage.getCartSummary();

      const discountCode = cartSummary.discount_code;

      await discountService.removeDiscountCode(discountCode);

      const cartSummaryObj = new CartSummary(cartSummary);

      cartSummaryObj.removeDiscountCode();

      await this.cartStorage.setCartSummary(cartSummaryObj);

      await this.calculateCartSummary();

      await store.dispatch('updateOrderPreferences');
    } catch (error) {
      throw Error('Failed to remove discount code:', error);
    }
  }

  async clearCart() {
    try {
      await this.cartStorage.setCartLines([]);
      await this.cartStorage.setCartSummary(new CartSummary());
      await this.cartStorage.set('establishment', null);
      await store.dispatch('updateCartStatus');
      await store.dispatch('updateCartSummaryStatus');
    } catch (error) {
      throw Error('Failed to clear cart:', error);
    }
  }

  async setComment(comment) {
    try {
      await this.cartStorage.set('comment', comment);
    } catch (error) {
      console.error('Failed to set comment:', error);
    }
  }

  async getComment() {
    try {
      return await this.cartStorage.get('comment') || '';
    } catch (error) {
      console.error('Failed to get comment:', error);
      return '';
    }
  }

  async getDeliveryCost() {
    try {
      const establishment = await this.getEstablishment();

      if(!establishment){
        return 0.0;
      }

      return establishment.delivery_price;
    } catch (error) {
      console.error('Failed to get delivery cost:', error);
      return 0;
    }
  }

  async getEstablishmentAddress(){
    try {
      const establishment = await this.getEstablishment();

      return establishment?.address;
    } catch (error) {
      console.error('Failed to get establishment address:', error);
      return '';
    }
  }

  async calculateCartOnDeliveryModeChange(deliveryMode) {
    // get cart lines
    const cartLines = await this.getCartLines();

    // set cart line price unit based on delivery mode
    const cartLinesWithPriceUnit = cartLines.map(line => {
      line.price_unit = deliveryMode === 'delivery' ? line.article.price_delivery : line.article.price_takeout;
      line.price_total = (line.price_unit + line.price_supplements) * (line.quantity);
      return line;
    })


    await this.cartStorage.setCartLines(cartLinesWithPriceUnit);

    // calculate cart summary
    await this.calculateCartSummary();
  }


  async checkForCartLineEstablishment(establishment) {
    const currentEstablishment = await this.cartStorage.get('establishment');
    const establishmentObj = new CartEstablishment(establishment);

    if(!currentEstablishment){
      await this.cartStorage.set('establishment', establishmentObj);
    }else if(currentEstablishment.id !== establishmentObj.id) {
      await this.clearCart();
      await this.cartStorage.set('establishment', establishmentObj);
    }

    // check min order amount and delivery cost
    const cartSummary = await this.getCartSummary();

    if(cartSummary.min_order_amount !== establishmentObj.min_order_amount){
      await this.setMinOrderAmount(establishmentObj.min_order_amount);
    }

    if(cartSummary.delivery_cost !== establishmentObj.delivery_price){
      await this.setDeliveryCost(establishmentObj.delivery_price);
    }
  }

  async getRemainingAmount() {
    try {
      const cartSummary = await this.getCartSummary();
      const deliveryMode = await cartService.getDeliveryMode();
      const establishment = await this.getEstablishment();
      const discountAmount = cartSummary.discount_amount ?? 0;

      if(deliveryMode !== 'delivery') {
        return 0;
      }

      const discountCode = cartSummary.discount_code;

      if(discountCode) {
        if(discountCode.ignore_partner_min){
          if(discountCode.min_order_amount){
            return discountCode.min_order_amount - cartSummary.sub_total;
          }
        }
      }

      if(!establishment){
        return 0;
      }

      const remainingAmount = establishment.min_order_amount - cartSummary.sub_total + discountAmount;

      return remainingAmount > 0 ? remainingAmount : 0;
    } catch (error) {
      console.error('Failed to get remaining amount:', error);
      return 0;
    }
  }
}

export const cartService = new CartService();
