import { iconStorage } from "@/flows/app/icons/stores/iconStorage.js";
import icons from "@/svg/index.js";

class IconService {
  constructor() {
    this.iconStorage = iconStorage;
  }

  async initIcons() {
    try {
      const initialized = await this.iconStorage.getInitialized();

      if (initialized) {
        return;
      }

      const allIcons = Object.keys(icons);

      for (const iconName of allIcons) {
        const iconPromise = icons[iconName];
        const iconHtml = await iconPromise;
        await this.iconStorage.set(iconName, iconHtml.default);
        await this.iconStorage.setInitialized(true);
      }
    } catch (error) {
      console.error('Failed to initialize icons:', error);
    }
  }

  async fetchIcon(name) {
    try {
      const icon = await this.iconStorage.get(name);

      if (icon) {
        return icon;
      } else {
        await iconStorage.setInitialized(false);

        return null;
      }
    } catch (error) {
      console.error(`Failed to fetch icon "${name}":`, error);
      return null;
    }
  }
}

export const iconService = new IconService();
