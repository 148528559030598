import * as Sentry from '@sentry/vue';
import Smartlook from 'smartlook-client';
import OneSignal from "onesignal-cordova-plugin";
import { FirebaseCrashlytics } from "@capacitor-firebase/crashlytics";
import { App as CapacitorApp } from '@capacitor/app';
import FirebaseWebConfig from "@/firebase/FirebaseWebConfig.js";
import PlatformService from "@/core/PlatformService.js";
import axios from "axios";
import { preferenceService } from "@/flows/app/preferences/services/preferenceService.js";
import { userService } from "@/services/app/userService.js";
import { iconService } from "@/flows/app/icons/services/iconService.js";
import router from '@/router/index.js';
import {initBackgroundChange, changeAppBackground} from "@/core/appTheme.js";


class PluginService {
  async initializeAll(app, router) {
    await this.initNonTrackingServices(router);

    if (await this.hasTrackingPermission()) {
      await this.initTrackingServices(app);
    }
  }

  async initNonTrackingServices(router) {
    await this.setAxiosSettings();
    await this.initIcons();
    await this.initDeepLinks();
    await this.loadGoogleMapsApi();
    await this.setVersion();
    await this.initBackgroundSync(router);
  }

  async initTrackingServices(app) {
    await this.initSentry(app);
    await this.initSmartlook();
    await this.initFirebase();
    await this.initOneSignal();
  }

  async hasTrackingPermission() {
    if(PlatformService.isNative()) {
      return await preferenceService.getTrackingPermission();

    }

    return true;
  }

  async initSentry(app) {
    Sentry.init({
      dsn: process.env.SENTRY_DSN_KEY,
      environment: process.env.APP_ENV,
      release: 'ready2eat@' + import.meta.env.VITE_APP_VERSION,
      dist: '1',
      app: app
    });
  }

  async initSmartlook() {
    if (PlatformService.isWeb() && PlatformService.isProduction()) {
      Smartlook.init(process.env.SMARTLOOK_KEY);
    }
  }

  async initFirebase() {
    if (!PlatformService.isNative()) {
      const firebaseConfig = new FirebaseWebConfig();
      window.firebase_app = firebaseConfig.app();
    } else if (PlatformService.isNative() && PlatformService.isIOS()) {
      await FirebaseCrashlytics.log({
        message: 'App started'
      });
    }
  }

  async initOneSignal() {
    if (PlatformService.isNative()) {
      OneSignal.initialize(process.env.ONESIGNAL_APP_ID);
    }
  }

  async setAxiosSettings() {
    axios.interceptors.request.use(
      async (config) => {
        const tokenData = await userService.getAuthTokenData();
        const platform = PlatformService.getPlatform();
        const version = await preferenceService.getAppVersion();

        if (tokenData?.access_token) {
          config.headers['Authorization'] = 'Bearer ' + tokenData.access_token;
        }

        config.headers['locale'] = localStorage.getItem('locale') ?? 'nl';
        config.headers['Accept-Language'] = localStorage.getItem('locale') ?? 'nl';
        config.headers['X-App-Version'] = version;
        config.headers['X-App-Platform'] = platform;
        config.headers['X-API-KEY-ID'] = process.env.API_KEY_ID;
        config.headers['X-API-SECRET'] = process.env.API_KEY_SECRET;

        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  }

  async initIcons() {
    await iconService.initIcons();
  }

  async initDeepLinks() {
    if (PlatformService.isNative() && PlatformService.isAndroid()) {
      CapacitorApp.addListener('appUrlOpen', (event) => {
        if (event.url.includes('ready2eat://app.ready2eat.be/')) {
          const slug = event.url.replace('ready2eat://app.ready2eat.be/', '');
          router.push({ path: slug }).catch((err) => {
            if (err.name !== 'NavigationDuplicated') {
              console.error(err);
            }
          });
        }
      });
    }
  }

  async loadGoogleMapsApi() {
    return new Promise((resolve, reject) => {
      const scriptId = 'google-maps-api-script';
      if (document.getElementById(scriptId)) {
        if (window.google && window.google.maps) {
          resolve();
          return;
        }
        // Script exists but API not loaded - remove it to try again
        document.getElementById(scriptId).remove();
      }

      const apiKey = process.env.GOOGLE_MAPS_WEB_API_KEY;

      if (!apiKey) {
        reject(new Error('Google Maps API key is missing'));
        return;
      }

      // Create a unique callback name
      const callbackName = 'initGoogleMaps_' + Date.now();
      window[callbackName] = () => {
        console.log('Google Maps API loaded successfully');
        delete window[callbackName]; // Clean up
        resolve();
      };

      const script = document.createElement('script');
      script.id = scriptId;
      script.async = true;
      script.defer = true;
      script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places&callback=${callbackName}`;

      script.onerror = (error) => {
        console.error('Failed to load Google Maps API:', error);
        delete window[callbackName]; // Clean up
        reject(new Error('Failed to load Google Maps API'));
      };

      document.head.appendChild(script);
    });
  }

  async setVersion() {
    if (PlatformService.isNative()) {
      const version = import.meta.env.VITE_APP_VERSION;
      await preferenceService.setAppVersion(version);
    }
  }

  async initBackgroundSync(router) {
    const now = new Date();
    const hour = now.getHours();
    let darkTheme = hour >= 22 || hour < 6;

    document.documentElement.setAttribute('data-theme', darkTheme ? 'dark' : 'light');

    // check if route is welcome screen
    if (router.currentRoute.value.name === 'welcome') {
      await initBackgroundChange(darkTheme);
    }


    router.beforeEach(async (to, from) => {
      await changeAppBackground(from, to);
    });

  }
}

export default new PluginService();
