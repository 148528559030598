<template>
  <AppModal>
    <div
      ref="modal"
      class="modal-establishment"
    >
      <div class="modal-header">
        <h2>{{ establishment.name }}</h2>

        <button class="reset exit-button" @click="close">
          <Icon name="exit" />
        </button>
      </div>
      <div class="modal-main">
        <div class="panel-map mb-m">
          <Map
            :height="200"
            :coordinates="{
              lat: establishment.address.latitude,
              lng: establishment.address.longitude,
            }"
          />
        </div>

        <app-panel class="mb-m" :title="__t('app.address')">
          {{ getFullAddressString(establishment.address) }}
        </app-panel>

        <app-panel class="mb-m" :title="__t('app.business_hours')">
          <ul>
            <template v-for="(day, i) in sortedBusinessHours" :key="`day_${i}`">
              <li>
                {{ __t(`app.day.${day.day}`) }}
                <span>{{ day.open_time_formatted }} - {{ day.close_time_formatted }}</span>
              </li>
            </template>
          </ul>
        </app-panel>

        <app-panel class="mb-m panel-contact" :title="__t('app.contact')">
          <ul>
            <li>
              {{ __t('app.email') }}
              <span>
                <a :href="`mailto:${establishment.contact.email}`">
                  {{ establishment.contact.email }}
                </a>
              </span>
            </li>
            <li>
              {{ __t('app.phone') }}
              <span>
                <a :href="`tel:${establishment.contact.phone}`">
                  {{ establishment.contact.phone }}</a>
              </span>
            </li>
          </ul>
        </app-panel>
      </div>
      <div class="modal-footer" />
    </div>
  </AppModal>
</template>

<script setup>
  import {ref, computed, defineProps} from 'vue';
  import Icon from '@/ui/icon/Icon.vue';
  import AppModal from '@/modals/AppModal.vue';
  import {modalController} from "@ionic/vue";
  import AppPanel from "@/modules/global/AppPanel.vue";
  import {__t} from "@/globals";
  import Map from "@/modules/global/Map.vue";
  import {days} from "@/config/constants.js";

  // Define props and emits
  const props = defineProps({
    establishment: {type: Object, required: true},
  });

  // Define business days order
  const dayOrder = days;

  // Computed property to sort business hours
  const sortedBusinessHours = computed(() => {
    return [...props.establishment.business_hours].sort(
      (a, b) => dayOrder.indexOf(a.day) - dayOrder.indexOf(b.day)
    );
  });

  const close = () => modalController.dismiss();

  // Refs for reactive variables
  const modal = ref(null);

  function getFullAddressString(address) {
    if (address) {
      return address.street + (address.number ? " " + address.number : "") + (address.bus ? " " + address.bus : "") +
        ", " + address.city.zip + " " + address.city.name;
    }
  }
</script>

<style lang="scss">
.modal-establishment-info {
  height: 100%;
  width: 100%;
  position: relative;
  overflow: auto;

  ion-header {
    padding-top: 0;
    height: var(--ion-safe-area-top);
  }

  .modal-header {
    padding: var(--padding-s);
    display: flex;
    align-items: center;

    svg {
      width: 1.4rem;
      height: 1.4rem;
      margin-right: var(--margin-s);
    }

    button.exit-button {
      margin-left: auto;
    }

    h2 {
      font-size: 1.4rem;
    }
  }

  .modal-main {
    padding: 0 var(--padding-m);
  }

  .panel-map {
    width: 100%;
    min-height: 20rem;
    border-radius: 1rem;
    overflow: hidden;
  }
}

[data-theme='dark'] {
  .modal-establishment {
    .modal-header {
      h2 {
        color: var(--r2e-white);
      }

      button.exit-button {
        margin-left: auto;

        svg {
          color: var(--r2e-secondary-200);
        }
      }
    }

    .modal-main {
      ul {
        li {
          span {
            a {
              color: var(--r2e-primary);
            }
          }
        }
      }
    }
  }
}
</style>
