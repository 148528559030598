import {AppConfigurationRepository} from "../repositories/AppConfigurationRepository.js";
import {AppConfigurationStore} from "../stores/AppConfigurationStore.js";
import {AppConfiguration} from "@/modules/app-configuration/models/AppConfiguration.js";
import {discountService} from "@/modules/discount/services/DiscountService.js";
import {userService} from "@/services/app/userService.js";
import {AppMessage} from "@/modules/app-configuration/models/AppMessage.js";

class AppConfigurationService {
  constructor() {
    this.appConfigurationRepository = new AppConfigurationRepository();
    this.appConfigurationStore = new AppConfigurationStore();
    this.appStateListeners = [];
  }

  async loadConfiguration() {
    try {
      const response = await this.appConfigurationRepository.fetchConfiguration();
      const authenticated = await userService.isAuthenticated();

      if(authenticated && response.auto_applied_discount_code) {
        await discountService.addAutoAppliedDiscountCode(response.auto_applied_discount_code);
      }

      if(response.message) {
        await this.addAppMessage(response.message);
      }

      const appConfiguration = new AppConfiguration(response);

      await this.appConfigurationStore.setAppConfiguration(appConfiguration);
    } catch (error) {
      console.error('Failed to load app configuration:', error);
      throw error;
    }
  }

  async getAppState() {
    try {
      const appConfiguration = await this.appConfigurationStore.getAppConfiguration();

      if(appConfiguration === null) {
        return 'active';
      }

      return appConfiguration.getAppState();
    }
    catch (error) {
      console.error('Failed to get app state:', error);
      throw error;
    }
  }

  async addAppMessage(message) {
    const appMessage = new AppMessage(message);
    await this.appConfigurationStore.addAppMessage(appMessage);
  }

  async getAppMessages() {
    const messages = await this.appConfigurationStore.getAppMessages();

    return messages.map(message => new AppMessage(message));
  }

  async dismissAppMessage(id) {
    await this.appConfigurationStore.dismissAppMessage(id);
  }

  async initializeEvent() {
    await this.loadConfiguration();

    // Set up interval for periodic updates
    this.configInterval = setInterval(() => {
      this.loadConfiguration();
    }, 60000);
  }

  stopEvent() {
    if (this.configInterval) {
      clearInterval(this.configInterval);
      this.configInterval = null;
    }
  }
}

export const appConfigurationService = new AppConfigurationService();
