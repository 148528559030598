<template>
  <div class="r2e-input datetime-inputs">
    <div class="input-field" :class="{ disabled }">
      <div class="datetime-group">
        <input
          type="text"
          :value="formattedDate"
          readonly
          @click="openDatePicker"
          placeholder="Select date"
          class="datetime-input"
        />
        <input
          type="text"
          :value="formattedTime"
          readonly
          @click="openTimePicker"
          placeholder="Select time"
          class="datetime-input"
        />
      </div>
    </div>
    <span v-if="error" class="error">{{ error }}</span>

    <ion-popover
      :is-open="isDateOpen"
      @did-dismiss="isDateOpen = false"
      class="datetime-popover"
    >
      <ion-datetime
        id="date-picker"
        presentation="date"
        prefer-wheel="true"
        :locale="locale"
        :value="internalValue"
        :min="formatDate(configuration?.minDate)"
        :max="formatDate(configuration?.maxDate)"
        :is-date-enabled="isDateEnabled"
        :show-default-buttons="true"
        :cancel-text="cancelText"
        :done-text="doneText"
        @ionChange="handleDateChange"
      ></ion-datetime>
    </ion-popover>

    <ion-popover
      :is-open="isTimeOpen"
      @did-dismiss="isTimeOpen = false"
      class="datetime-popover"
    >
      <ion-datetime
        id="time-picker"
        presentation="time"
        prefer-wheel="true"
        :locale="locale"
        :value="internalValue"
        :hour-values="getHourValues"
        :minute-values="minuteValues"
        :show-default-buttons="true"
        :cancel-text="cancelText"
        :done-text="doneText"
        @ionChange="handleTimeChange"
      ></ion-datetime>
    </ion-popover>
  </div>
</template>

<script setup>
  import {ref, computed, watch} from 'vue'
  import { IonDatetime, IonPopover } from '@ionic/vue'
  import moment from 'moment'
  import {__t} from "@/globals.js";

  const props = defineProps({
    value: { type: String, default: null },
    error: { type: String, default: null },
    disabled: { type: Boolean, default: false },
    configuration: {
      type: Object,
      required: true,
      default: () => ({
        minDate: new Date().toISOString(),
        maxDate: null,
        disabledDates: [],
        minTime: { hours: "16", minutes: "00", seconds: "00" },
        maxTime: { hours: "23", minutes: "00", seconds: "00" },
        startTime: { hours: "16", minutes: "00", seconds: "00" }
      })
    }
  })

  const emit = defineEmits(['set'])
  const locale = ref('nl-BE')
  const internalValue = ref(props.value || props.configuration?.minDate)
  const isDateOpen = ref(false)
  const isTimeOpen = ref(false)
  const doneText = __t('app.select')
  const cancelText = __t('app.cancel')

  const formatDate = (date) => {
    return moment(date).format('YYYY-MM-DDTHH:mm')
  }

  const formattedDate = computed(() => {
    return internalValue.value ? moment(internalValue.value).format('DD/MM/YYYY') : ''
  })

  const formattedTime = computed(() => {
    return internalValue.value ? moment(internalValue.value).format('HH:mm') : ''
  })

  watch(() => props.value, newVal => {
    if (newVal) {
      internalValue.value = newVal
    }
  })

  const openDatePicker = () => {
    isDateOpen.value = true
  }

  const openTimePicker = () => {
    isTimeOpen.value = true
  }

  const handleDateChange = (e) => {
    const selectedDate = moment(e.detail.value)
    const currentTime = moment(internalValue.value)

    selectedDate.hour(currentTime.hour())
    selectedDate.minute(currentTime.minute())

    internalValue.value = selectedDate.toISOString()
    isDateOpen.value = false
    emit('set', internalValue.value)
  }

  const handleTimeChange = (e) => {
    const selectedTime = moment(e.detail.value)
    const currentDate = moment(internalValue.value)

    currentDate.hour(selectedTime.hour())
    currentDate.minute(selectedTime.minute())

    internalValue.value = currentDate.toISOString()
    isTimeOpen.value = false
    emit('set', internalValue.value)
  }

  const isDateEnabled = (dateString) => {
    const date = moment(dateString).format('YYYY-MM-DDTHH:mm')
    return !props.configuration?.disabledDates?.some(
      d => moment(d).format('YYYY-MM-DDTHH:mm') === date
    )
  }

  const getHourValues = computed(() => {
    const min = parseInt(props.configuration?.minTime?.hours) || 0
    const max = parseInt(props.configuration?.maxTime?.hours) || 23
    const hours = []
    for (let i = min; i <= max; i++) {
      hours.push(i)
    }
    return hours
  })

  const minuteValues = computed(() => {
    return [0, 15, 30, 45]
  })
</script>

<style lang="scss">
.datetime-inputs {
  .datetime-group {
    display: flex;
    gap: var(--margin-xs);
    background: var(--r2e-secondary-100);
    border: 1px solid transparent;
    border-radius: 4px;
    transition: var(--effect);
    height: 6.1rem;
    padding: 0 0.8rem;

    &:hover {
      border: 1px solid var(--r2e-secondary-200);
    }

    .datetime-input {
      flex: 1;
      background: transparent;
      border: none;
      color: var(--r2e-secondary);
      font-size: 1.4rem;
      font-weight: 500;
      padding: 1.9rem 0.8rem;
      cursor: pointer;

      &::placeholder {
        color: var(--r2e-secondary-200);
        font-weight: 500;
        opacity: 1;
      }

      &:focus {
        outline: none;
      }

      &:first-child {
        border-right: 1px solid rgba(0, 0, 0, 0.08);
        border-radius: 0;
        margin-right: var(--margin-xs);
      }
    }
  }

  &.error .datetime-group {
    border: 1px solid var(--r2e-error);
    background-color: var(--r2e-primary-100);
  }
}

.datetime-popover {
  --width: auto;
  --max-width: 320px;

  ion-datetime {
    margin: 0;
    padding: 0;
  }
}

::v-deep(ion-datetime) .calendar-next-prev {
  display: none !important;
}
</style>
