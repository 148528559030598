import {DiscountCode} from "@/modules/discount/models/DiscountCode.js";

export default class CartSummary {
  constructor(cartSummary) {
    this.sub_total = 0.00;
    this.fee = 0.00;
    this.delivery_cost = 0.00;
    this.service_cost = 0.00;
    this.total = 0.00;

    this.discount_code = null;

    if(cartSummary) {
      this.init(cartSummary)
    }
  }

  init(data){
    this.sub_total = data.sub_total || 0.00;
    this.fee = data.fee || 0.00;
    this.delivery_cost = data.delivery_cost || 0.00;
    this.service_cost = data.service_cost || 0.00;
    this.total = data.total || 0.00;

    this.discount_code = data.discount_code ? new DiscountCode(data.discount_code) : null;
  }

  setDiscountCode(data) {
    this.discount_code = new DiscountCode(data);
  }

  removeDiscountCode() {
    this.discount_code = null;
  }

  calculate(items, deliveryMode, paymentMethod) {
    this.sub_total = items.reduce((total, item) => total + (item.price_total || 0), 0);
    let total_without_service_cost = 0.00;

    if(deliveryMode === 'delivery')
    {
      total_without_service_cost = this.sub_total
        - this.getDiscountAmount()
        + this.fee
        + this.delivery_cost
    } else {
      total_without_service_cost = this.sub_total
        + this.fee;
    }

    this.service_cost = this.calculateServiceCost(total_without_service_cost);

    if(paymentMethod !== 'online') {
      this.service_cost = Math.ceil(this.service_cost);
    }

    this.total = total_without_service_cost + this.service_cost;
  }

  getDiscountAmount() {
    const discountCode = this.discount_code;

    if (discountCode) {
      const meetsMinOrderAmount = discountCode.ignore_partner_min || discountCode.min_order_amount <= this.sub_total;

      if (meetsMinOrderAmount) {
        return discountCode.getAmount();
      }
    }

    return 0;
  }

  calculateServiceCost(orderTotal) {
    if (orderTotal <= 50) {
      return 0.75;
    } else if (orderTotal > 50 && orderTotal <= 100) {
      return 1.49;
    } else if (orderTotal > 100 && orderTotal <= 150) {
      return 1.99;
    } else if (orderTotal > 150 && orderTotal <= 200) {
      return 2.49;
    } else if (orderTotal > 200 && orderTotal <= 250) {
      return 2.99;
    } else {
      return 3.49;
    }
  }
}
