<template>
  <ion-app>
    <div id="toast-global" class="toast-slot-global" />
    <ion-router-outlet />
  </ion-app>
</template>

<script setup>
  import { onBeforeMount, onMounted, onUnmounted, ref, watch} from 'vue';
  import {IonApp, IonRouterOutlet} from "@ionic/vue";
  import {$openModal} from "@/globals.js";
  import {apiUrl} from "@/configuration/api.js";
  import axios from "axios";
  import {useRouter} from "vue-router";
  import {Network} from "@capacitor/network";
  import PlatformService from "@/core/PlatformService.js";
  import {preferenceService} from "@/flows/app/preferences/services/preferenceService.js";
  import {authService} from "@/services/auth/authService.js";
  import { SplashScreen } from '@capacitor/splash-screen';
  import {appConfigurationService} from "@/modules/app-configuration/services/AppConfigurationService.js";
  import {App as CapacitorApp} from "@capacitor/app";

  const router = useRouter();
  const connected = ref(true);

  const onboardingCompleted = ref(null);

  async function checkAppVersion() {
    if(PlatformService.isNative()){
      return;
    }

    const currentVersion = await preferenceService.getAppVersion();

    await axios.get(apiUrl('customer/version')).then(({data}) => {
      const newVersion = data.data.version;

      if(currentVersion === newVersion){
        console.log('app is up to date');
      } else {
        console.log('newVersion:', newVersion);


        $openModal('update-version', {version: newVersion });
      }
    });
  }

  async function checkInternetConnection() {
    const status = await Network.getStatus();
    connected.value = status.connected;

    if (!connected.value) {
      await router.push({ name: 'no-connection' });
    }

    await Network.addListener('networkStatusChange', (status) => {
      connected.value = status.connected;

      if (!connected.value) {
        router.push({name: 'no-connection'});
      }
    });
  }

  const checkIfOnboardingCompleted = async () => {
    if (PlatformService.isNative()) {
      onboardingCompleted.value = preferenceService.getOnboardingCompleted();
      if (!onboardingCompleted.value) {
        await authService.logout();

        await router.push({name: 'welcome'});
      }

    }
  }

  onBeforeMount(async () => {
    await checkIfOnboardingCompleted();
  });

  watch(connected, (newConnected) => {
    console.log('connected:', newConnected);
  });

  onMounted(async () => {
    await appConfigurationService.initializeEvent();
    await checkIfOnboardingCompleted();
    await checkAppVersion();
    await checkInternetConnection();
    await SplashScreen.hide();
  });

  onUnmounted(() => {
    appConfigurationService.stopEvent();
  });
</script>

<style lang="scss">
  :root {
    --app-background: var(--r2e-background);
    --ion-background-color: var(--r2e-background);
  }

  [data-theme="dark"] {
    :root {
      --app-background: var(--r2e-secondary-600);
      --ion-background-color: var(--r2e-secondary-600);
    }

    * {
      --app-background: var(--r2e-secondary-600);
      --ion-background-color: var(--r2e-secondary-600);
    }
  }

  .toast-slot-global {
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 9999;
    pointer-events: none;
  }

  .alert-wrapper.sc-ion-alert-md {
    background-color: var(--r2e-white);
    font-family: 'Poppins', sans-serif;
  }

  .ios .test-alert button.alert-button {
    font-weight: 600;
    font-size: 1.2rem;
  }
</style>
